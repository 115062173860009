import BaseAPI from './BaseAPI';

// Array of identifiers for endpoints,
// (base of every endpoint path, eg ads/, ads/23, etc)
const CORE_ENDPOINTS = [
  'accounts',
  'activations',
  'ads',
  'attributes',
  'changes',
  'channels',
  'datasheets',
  'debug',
  'sample-data',
  'dynamic-integrations',
  'goals',
  'goals-tracking',
  'images',
  'integrations',
  'matchables',
  'orders',
  'partners',
  'partner-verticals',
  // 'access/permissions',
  // 'platform', // legacy platform api
  'phone-numbers',
  'publishers',
  'routing-rules',
  'rules',
  'scripts',
  'sources',
  'source-tokens',
  'tenants',
  'tokens',
  'users',
  'vendors',
  'verticals',
];

/*
 * API for core Mastodon function.
 * An instance of this class is created in AppProvider and available in react context.
 */
class CoreAPI extends BaseAPI {
  constructor(requestCallback) {
    // Create CRUD methods for each core endpoint
    super(process.env.API_URL_CORE, CORE_ENDPOINTS, requestCallback);
    this.createSpecialMethods();
  }

  createSpecialMethods = () => {
    this.endpoints.rules.export = (id) => this.makeRequest('get', `rules/${id}/export`, null, { accept: 'text' });
    this.endpoints.rules.exportChannels = (id) => this.makeRequest('get', `rules/${id}/export/channels`, null, { accept: 'text' });
    this.endpoints.rules.import = (data) => this.makeRequest('post', `rules/${data.id}/import`, data.content, { bodyType: 'text' });
    this.endpoints.rules.importChannels = (data) => this.makeRequest('post', `rules/${data.id}/import/channels`, data.content, { bodyType: 'text' });
    this.endpoints.rules.createBatch = (data) => this.makeRequest('post', `rules/${data.id}/rules`, data);
    this.endpoints.routingRules.createBatch = (data) => this.makeRequest('post', `routing-rules/${data.id}/rules`, data);

    this.endpoints.attributes.groups = () => this.makeRequest('get', 'attributes/groups');

    this.endpoints.images.upload = (formData) => this.makeRequest(
      'post',
      'images/upload',
      formData,
    );

    this.endpoints.datasheets.updateFile = (id, formData) => this.makeRequest(
      'post',
      `datasheets/${id}/file`,
      formData,
    );

    this.endpoints.partners.upload = (formData) => this.makeRequest(
      'post',
      'partners/upload',
      formData,
    );

    this.endpoints.datasheets.download = (id) => this.makeRequest(
      'get',
      `datasheets/download/${id}`,
      null,
      { responseType: 'blob' },
    );

    this.endpoints.goalsTracking.download = (id) => this.makeRequest(
      'get',
      `goals-tracking/download/${id}`,
      null,
      { responseType: 'blob' },
    );

    this.endpoints.accounts.options = (params) => this.makeRequest('get', 'accounts/options', params, { accept: 'text' });
    this.endpoints.debug.auctionsList = (params) => this.makeRequest('get', 'debug/auctions', params, { accept: 'text' });
    this.endpoints.debug.auctionHttpLogs = (id) => this.makeRequest('get', `debug/auction-http-logs/${id}`, null, { accept: 'text' });
    this.endpoints.debug.deliveriesList = (params) => this.makeRequest('get', 'debug/deliveries', params, { accept: 'text' });
    this.endpoints.debug.callsList = (params) => this.makeRequest('get', 'debug/calls', params, { accept: 'text' });
    this.endpoints.debug.auctions = (id) => this.makeRequest('get', `debug/auctions/${id}`, null, { accept: 'text' });
    this.endpoints.debug.deliveries = (id) => this.makeRequest('get', `debug/deliveries/${id}`, null, { accept: 'text' });
    this.endpoints.debug.matchables = (id) => this.makeRequest('get', `matchables/${id}`, {minimal: true}, { accept: 'text' });

    // todo move to dedicated delivery requests endpoint
    this.endpoints.debug.getDeliveryRequest = (id) => this.makeRequest('get', `debug/delivery-requests/${id}`, null, { accept: 'text' });
    this.endpoints.debug.sendDeliveryRequest = (params) => this.makeRequest('post', `delivery-requests`, params, { accept: 'text' }, true);
    this.endpoints.debug.listDeliveryRequest = (params) => this.makeRequest('get', `delivery-requests`, params, { accept: 'text' }, true);
    this.endpoints.debug.updateDeliveryRequest = (id, params) => this.makeRequest('put', `delivery-requests/${id}`, params, { accept: 'text' }, true);
    this.endpoints.debug.deleteDeliveryRequest = (id) => this.makeRequest('delete', `delivery-requests/${id}`, null, { accept: 'text' }, true);

    this.endpoints.sampleData.listSampleData = (params) => this.makeRequest('get', `sample-data`, params, { accept: 'text' }, true);
    this.endpoints.sampleData.getSampleData = (id) => this.makeRequest('get', `sample-data/${id}`, null, { accept: 'text' }, true);
    this.endpoints.sampleData.createSampleData = (params) => this.makeRequest('post', `sample-data`, params, { accept: 'text' }, true);
    this.endpoints.sampleData.updateSampleData = (id, params) => this.makeRequest('put', `sample-data/${id}`, params, { accept: 'text' }, true);
    this.endpoints.sampleData.deleteSampleData = (id) => this.makeRequest('delete', `sample-data/${id}`, null, { accept: 'text' }, true);

    this.endpoints.debug.listDeliveryQueue = (params) => this.makeRequest('get', `debug/delivery-queue`, params, { accept: 'text' }, true);
    this.endpoints.debug.cancelDelivery = (id) => this.makeRequest('delete', `debug/delivery-queue/${id}`, {}, { accept: 'text' }, true);
    this.endpoints.debug.bumpDelivery = (id) => this.makeRequest('put', `debug/delivery-queue/bump/${id}`, {}, { accept: 'text' }, true);
    this.endpoints.debug.template = (params) => this.makeRequest('post', `debug/template`, params);
    this.endpoints.changes.export = {
      bids: (params) => this.makeRequest("get", "changes/export/bids", params, {responseType: "blob"}),
      channelModifiers: (params) => this.makeRequest("get", "changes/export/channel-modifiers", params, {responseType: "blob"})
    }

    this.endpoints.activations.getUserActivation = (userId) => this.makeRequest('get', `activations/user/${userId}`, {}, { accept: 'text' }, true);


    this.endpoints.integrations.listRevisions = (id, params) => this.makeRequest('get', `integrations/${id}/versions`, params);
    this.endpoints.integrations.showRevision = (id, version) => this.makeRequest('get', `integrations/${id}/versions/${version}`);

    this.endpoints.favorites = {
      list: () => this.makeRequest('get', 'favorites', null, { accept: 'text' }),
      addOrder: (id) => this.makeRequest('post', `favorites/orders/${id}`, null, { accept: 'text' }),
      deleteOrder: (id) => this.makeRequest('delete', `favorites/orders/${id}`, null, { accept: 'text' }),
    }
  };

  login = (credentials) => this.makeRequest('post', `${this.url}/auth-tokens`, credentials);

  requestPasswordReset = (email) => this.makeRequest('post', `${this.url}/password-resets`, { email });

  resetPassword = (token, password) => this.makeRequest('put', `${this.url}/password-resets/${token}`, { password });
}

export default CoreAPI;
