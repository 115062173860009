import React, {FC} from 'react'

/*
 * A two column row meant for listing key/value data
 */
type DataRowProps = {
  label:string;
  className?:string;
  children:React.ReactNode;
}

//TODO: level? sub-row? label width?
//TODO: separate css
// sub-row used only in auction detail Bid and Block
export const DataRow:FC<DataRowProps> = ({
  label,
  className="",
  children
}) => {

  return (
    <div className={`data-row ${className}`}>
      <label>{label}</label>
      <div className="value">
        {children}
      </div>
    </div>
  )
}
