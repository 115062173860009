import React, {ChangeEvent, useState} from "react";
import StackedBarChart from "../../Components/Chart/StackedBarChart";
import {DefaultStackedChartOptions} from "../../Components/Chart/chart";
import { Nav, TabContent, TabPane} from "reactstrap";
import RejectionCategorySelect from "./List/RejectionCategorySelect";
import IntegrationRejectionList from ".//List/IntegrationRejectionList";
import NumberFormat from "react-number-format";
import {TabNav} from "@thedmsgroup/mastodon-ui-components";

// can't get ChartOptions type to validate type:'time'
const StackedChartOptions = DefaultStackedChartOptions as any;


/*
 * Tabbed widget that displays lists and graphs derived from aggregate
 * data about delivery rejections, from the read service.
 *
 * TODO: when aggregating by day, the label on the last day bar is missing
 *  ( or if just one day, there is no label)
 *  Doesn't happen for one hour bar
 */
type DeliveryRejectStatProps = {
  rejectCategory:string;
  onChangeRejectCategory:(category:string) => void;
  data: Record<string, any>,
  isLoading:boolean,
  className?:string
  aggInterval?:string
  intervalLabel?:string
}

const DeliveryRejectStat = ({
  rejectCategory,
  onChangeRejectCategory,
  data = [],
  className = "",
  aggInterval,
  intervalLabel,

}: DeliveryRejectStatProps) => {

  const [activeTab, setActiveTab] = useState("categories");
  const [selectedRejectCategory, setSelectedRejectCategory] = useState<string>(rejectCategory);

  const handleChangeRejectCategory = (e:ChangeEvent<HTMLInputElement>) => {
    setSelectedRejectCategory(e.target.value);
    onChangeRejectCategory(e.target.value);
  }


  return (
    <div className={`list-and-chart ${className}`}>

      <Nav tabs className="mb-2">
        <TabNav
          isActive={activeTab === "categories"}
          setActive={setActiveTab}
          id="categories"
        >
          By Category
        </TabNav>
        <TabNav
          isActive={activeTab === "integrations"}
          setActive={setActiveTab}
          id="integrations"
        >
          By Integration
        </TabNav>

      </Nav>

      <TabContent activeTab={activeTab} style={{ height: '220px' }}>
        <TabPane tabId="categories">
          <div className="chart-grid">

            <div className="scrolling-list">
              <div className="form-section border">
                <div className="list thin-scrollbar">
                  {data.rejectionCategoryTotals && data.rejectionCategoryTotals.length > 0
                    ? (
                    data.rejectionCategoryTotals.map((bkt:any,i:number) => (
                    <div className="data-row" key={i}>
                      <label>{bkt.key}</label>
                      <div className="text-end me-1"><NumberFormat value={bkt.doc_count} displayType="text" thousandSeparator /></div>
                    </div>
                    ))) : (
                    <i>None found</i>
                  )}
                </div>

              </div>
            </div>


            {data.rejectionsOverTimeByCategory && (
              <StackedBarChart
                labels={data.rejectionsOverTimeByCategory.labels}
                datasets={data.rejectionsOverTimeByCategory.datasets}
                options={StackedChartOptions}
                styles={{ height: '200px' }}
                aggInterval={aggInterval ?? ""}
                xLabel={`Sent per ${intervalLabel}`}
              />
            )}


          </div>
        </TabPane>

        <TabPane tabId="integrations">

          <div className="chart-grid integrations-rejected">
            <div>
              <div className="mb-1">
                <RejectionCategorySelect selected={selectedRejectCategory} onChange={handleChangeRejectCategory} />
              </div>
              <IntegrationRejectionList
                rejectionsByCategory={data.integrationRejectionTotals}
                category={selectedRejectCategory}
                className="scrolling-list"
              />
            </div>

            {data.rejectionsOverTimeByIntegration && (
              <StackedBarChart
                labels={data.rejectionsOverTimeByIntegration.labels}
                datasets={data.rejectionsOverTimeByIntegration.datasets}
                options={StackedChartOptions}
                styles={{ height: '200px' }}
                aggInterval={aggInterval ?? ""}
                xLabel={`Sent per ${intervalLabel}`}
              />
            )}

          </div>

        </TabPane>

      </TabContent>

    </div>
  )
}

export default DeliveryRejectStat;

