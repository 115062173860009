import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, FormGroup, Label, FormText, Button, Input,
} from 'reactstrap';
import {MaskedInputGroup} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";
import Flatpickr from 'react-flatpickr';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components"
import IconCheckbox from '../../../../Components/Form/IconCheckbox';
import RulesContext from '../../../../Providers/RulesContext';
import useTimezoneDisplay from '../../../../Hooks/useTimezoneDisplay';
import {TokenTextarea} from "@thedmsgroup/mastodon-ui-components";
import {startOfHour, startOfMinute, addHours, compareAsc} from "date-fns";

// Mask ddd,ddd
// const modifierMask = createNumberMask({
//   prefix: '',
//   allowDecimal: false,
//   integerLimit: 3,
// });

/*
 * Special settings for advanced life forms only
 */
const Advanced = ({
  buyer_name,
  buyer_group,
  internalBidModifier,
  qualifyingRateStart,
  trackQualifyingRate,
  ignoreSaleRate,
  vendorAttributes,
  excludeProfitReporting,
  billMissedCalls,
  maxUnqualifiedCalls,
  unqualifiedCallsStart,
  duplicateWindow,
  tokens,
  timezone,
  onChange,
  errors,
}) => {
  const ctx = useContext(RulesContext);

  const defaultRateStart = startOfHour(addHours(new Date(), 1));

  const { timezoneDisplay } = useTimezoneDisplay(timezone);

  const pickerOptions = {
    enableTime: true,
    allowInput: true,
    maxDate: defaultRateStart, //'today',
    // minDate: "today",
    dateFormat: 'Y-m-d H:i',
    altInput: true,
    altFormat: 'F j, Y h:i K',
    minuteIncrement: 15,
    isRangeValid: true,
    defaultHour: 0,
    defaultMinute: 0,
    // onChange does not fire when typing in the input, only when you tab out
    // of the input or pick from the dropdown. This onClose function is a workaround.
    // https://github.com/flatpickr/flatpickr/issues/1551#issuecomment-522942541
    onClose(dates, currentdatestring, picker){
      picker.setDate(picker.altInput.value, true, picker.config.altFormat)
    }
    // parseDate: (strDate, format) => {
    //   const pd =  utcToLocalDate(strDate, timezone);
    //   console.log('Advanced.js:parseDate: ', pd, ', FROM: ', strDate, ", Format:"+format);
    //   return pd;
    // },
  };

  // Restrict a date to now or past
  const dateUpToNow = (date) => {
    const now = new Date();
    return compareAsc(date, now) === 1 ? now : date;
  }

  const handleChangeQRResetDate = (date) => {
    if (date) {
      // const utc = dateToUTC(date, timezone);
      const utc = zonedTimeToUtc(startOfMinute(dateUpToNow(date)), timezone).toISOString();
      onChange('qualifying_rate_start', utc);
    }
  };

  const handleClearQRStartDate = () => {
    onChange('qualifying_rate_start', null);
  };


  const handleQRStartDateNow = () => {
    const now = startOfMinute(new Date());
    onChange('qualifying_rate_start', zonedTimeToUtc(now, timezone).toISOString());
  };

  const handleChangeResetUnqualifiedDate = (date) => {
    if (date) {
      const utc = zonedTimeToUtc(startOfMinute(dateUpToNow(date)), timezone).toISOString();
      onChange('unqualified_calls_start', utc);
    }
  };

  const handleUnqualifiedCallsStartDateNow = () => {
    const now = startOfMinute(new Date());
    onChange('unqualified_calls_start', zonedTimeToUtc(now, timezone).toISOString());
  };


  const handleClearUnqualifiedCallsStartDate = () => {
    onChange('unqualified_calls_start', null);
  };

  // API is fussy about this true/false/null setting
  // We need to ensure that the empty value is set to the API as null
  const handleChangeBillMissedCalls = (e) => {
    onChange('bill_missed_calls', e.target.value === '' ? null : e.target.value === 'true');
  };

  const handleChangeVendorAttributes = (value) => {
    onChange('vendor_attributes', value);
  };

  const handleUrlPreview = async(url) => {
    const result =  await debugApi.fetch( {
      template:url
    });
    return result ? result.result : "";
  }

  return (
    <div>
      {(errors.internal_bid_modifier || errors.qualifying_rate_start || errors.vendor_attributes)
      && (
      <StandardAlert color="info" className="validation">
        Please correct the errors below before continuing
      </StandardAlert>
      )}

      <div className="form-section">
        <div className="form-section-header">Qualifying Rate Tracking</div>
        <p>By default, the ratio of sales vs non-sales is included in bidding calculations and affects future win rate. Settings in this section allow you to customize this behavior.</p>

        <IconCheckbox
          name="ignore_sale_rate"
          value={Boolean(ignoreSaleRate)}
          onChange={onChange}
          className="mt-3"
        >
          Disable Qualifying Rate
        </IconCheckbox>

        <IconCheckbox
          name="track_qualifying_rate"
          value={Boolean(trackQualifyingRate)}
          onChange={onChange}
          className="mt-3"
        >
          Track Qualifying Rate specific to this Rule
        </IconCheckbox>
        <p>By default, qualifying rate is tracked between source and buyer. If you enable this setting, this rule is tracking its own qualifying rate.
          You might do this if there is something special about this rule that significantly affects sale rate, and you want to isolate this behavior from the overall sale rate.</p>

        <div className="mt-3">
          <Row>

            <Col sm={12} md={9}>

              <FormGroup>
                <Label>
                  Start Date
                </Label>
                <p>Start rate tracking from the specified date. If not set,
                  all available historic data will be used.</p>


                <div className="d-flex align-items-center">
                  <div style={{flexBasis:'300px'}}>
                    <Flatpickr
                      options={pickerOptions}
                      value={qualifyingRateStart ? utcToZonedTime(new Date(qualifyingRateStart), timezone) : null}
                      className="form-control"
                      placeholder="Set a date and time"
                      onChange={(dates) => handleChangeQRResetDate(dates[0])}
                    />

                  </div>

                  {qualifyingRateStart && <Button color="link" className="ms-1" onClick={handleClearQRStartDate}>Clear</Button>}
                  <Button color="link"  onClick={handleQRStartDateNow}>Set to Now</Button>

                </div>

                {timezoneDisplay && <FormText><i>Timezone: {timezoneDisplay} </i></FormText> }

              </FormGroup>

            </Col>
          </Row>
        </div>

      </div>

      <div className="form-section mt-2">
        <div className="form-section-header">Internal Bid Modifier</div>
        <p>Modifies internal bid for sorting purposes. Does not affect the actual bid shown to vendors.</p>

        <FormGroup>
          <MaskedInputGroup
            mask={Number}
            unmask="typed"
            padFractionalZeros={false}
            scale={0}
            min={0}
            name="internal_bid_modifier"
            value={internalBidModifier || ''}
            onChange={onChange}
            suffix="%"
            className="teeny"
          />
        </FormGroup>
      </div>

      <div className="form-section mt-2">
        <div className="form-section-header">Buyer Name / Group</div>
        <p>Attributes bids to specified buyer/group. This is used to de-duplicate bids within a feed - only one bid per buyer name is returned inside a single feed.</p>

        <FormGroup>
          <Label>Buyer Name</Label>
          <Input
            name="buyer_name"
            value={buyer_name || ''}
            onChange={onChange}
            placeholder="defaults to account name"
            className="w-25"
          />
        </FormGroup>

        <FormGroup>
          <Label>Buyer Group</Label>
          <Input
            name="buyer_group"
            value={buyer_group || ''}
            onChange={onChange}
            placeholder="defaults to account buyer group"
            className="w-25"
          />
        </FormGroup>
      </div>

      <div className="form-section mt-2">
        <div className="form-section-header">Vendor Attributes</div>
        <p>
          Provide data to the vendor in match responses. Insert placeholder data with token names surrounded
          by braces (in quotes):
          <i>&quot;insured&quot;:&quot;&#123;currently_insured&#125;&quot;</i>
          .&nbsp;
        </p>
        <FormGroup className="mt-2">

          <FormText className="mb-2" />

            <TokenTextarea
              tokens={tokens}
              text={vendorAttributes || ""}
              onChange={handleChangeVendorAttributes}
              rows={5}
              error={errors.vendor_attributes}
              invalid={Boolean(errors.vendor_attributes)}
            />

        </FormGroup>

      </div>

      <div className="form-section">
        <div className="form-section-header">Exclude from Profit Reporting</div>
        <p>Do not log revenue to DMS Profit Reporting. This will apply to all descendant rules.</p>

        <IconCheckbox
          name="exclude_profit_reporting"
          value={Boolean(excludeProfitReporting)}
          onChange={onChange}
          className="mt-3"
        >
          Exclude from profit reporting
        </IconCheckbox>
      </div>

      {ctx.product === 'calls' && (
        <div className="form-section">
          <div className="form-section-header">Call Settings</div>
          <Row>
              <Col sm={12} md={8}>
              <FormGroup>
                <Label for="bill_missed_calls">Bill Missed Calls</Label>
                <Input type="select" name="bill_missed_calls" value={billMissedCalls ?? ''} onChange={handleChangeBillMissedCalls}>
                  <option value="">Inherit parent setting</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="max_unqualified_calls" className="d-block">Max Unqualified Calls</Label>
                <p>After this many unqualified calls, further matches will be blocked.</p>
                <Input
                  type="number"
                  name="max_unqualified_calls"
                  value={maxUnqualifiedCalls ?? ''}
                  className="tiny"
                  onChange={onChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="unqualified_calls_start_date">Unqualified Start Date</Label>
                <p>Use this to reset unqualified calls blocking.</p>

                <div className="d-flex align-items-center">
                  <div style={{flexBasis:'300px'}}>
                    <Flatpickr
                      options={pickerOptions}
                      value={unqualifiedCallsStart ? utcToZonedTime(new Date(unqualifiedCallsStart), timezone) : null}
                      className="form-control"
                      placeholder="Set a date and time"
                      onChange={(dates) => handleChangeResetUnqualifiedDate(dates[0])}
                    />



                  </div>
                  <div>
                    {unqualifiedCallsStart && <Button color="link" className="ms-1" onClick={handleClearUnqualifiedCallsStartDate}>Clear</Button>}
                    <Button color="link" onClick={handleUnqualifiedCallsStartDateNow}>Set to Now</Button>
                  </div>
                </div>

                {timezoneDisplay && <FormText><i>Timezone: {timezoneDisplay} </i></FormText> }


              </FormGroup>
            </Col>
          </Row>
        </div>
      )}

      <div className="form-section">
        <div className="form-section-header">Duplicate Settings</div>
        <div className="mt-3">
            <FormGroup>
              <Label>Duplicate Window (in days)</Label>
              <p>The amount of days to check for duplicates (-1 is disabled and 0 is infinite).</p>
              <Input
                name="duplicate_window"
                value={duplicateWindow || ''}
                onChange={onChange}
                placeholder="defaults to predefined values"
                className="w-25"
              />
            </FormGroup>
        </div>

      </div>

    </div>
  );
};

/* For when we do scheduling of concurrency or duration, use this instead:

          <Row>
            <Col sm={12}>
              <SchedulableSettingInput
                setting="concurrency"
                scheduleSetting="call_concurrency_schedule"
                title="Call Concurrency"
                config={ConcurrencyScheduleConfig}
                hideInput={false}
                value={concurrency || ConcurrencyScheduleConfig.default}
                schedule={concurrencySchedule}
                onChange={onChange}
                size="sm"
              />

            </Col>
          </Row>
 */

Advanced.propTypes = {
  internalBidModifier: PropTypes.number,
  trackQualifyingRate: PropTypes.bool,
  ignoreSaleRate: PropTypes.bool,
  qualifyingRateStart: PropTypes.string,
  vendorAttributes: PropTypes.string,
  excludeProfitReporting: PropTypes.bool,
  billMissedCalls: PropTypes.bool,
  duplicateWindow: PropTypes.number,
  maxUnqualifiedCalls: PropTypes.number,
  unqualifiedCallsStart: PropTypes.string,
  tokens: PropTypes.array,
  timezone: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(Advanced);
