import React, { useContext, useState, useEffect } from 'react';
import {
  TabPane,
  Button, TabContent, Input, Label, FormText,
} from 'reactstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import copy from 'fast-copy';
import lazify from '../../../../utils/Lazify';
import PageSection from '../../../../Layout/PageSection';
import RulesContext from '../../../../Providers/RulesContext';
import Header from './Header';
import Summary from './Summary';
import Bidding from './Bidding';
import Budgets from './Budgets';
import CallSettings from './CallSettings';
import Channels from './Channels';
import Enrichments from './Enrichments';
import Destination from './Destination';
import Tracking from './Tracking';
import Advanced from './Advanced';
import Tag from './Tag';
import Ads from './Ads';
import NavTabs from './NavTabs';
import useFormState from '../../../../Hooks/useFormState';
import {
  JSONValidation,
  PhoneNumberValidation,
  PixelValidation,
} from '../../../../Components/Form/CustomValidation';
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import LoadingBar from '../../../../Layout/LoadingBar';
import ActionsPanel from '../ActionsPanel';
import SaleConfirmation from "./SaleConfirmation";
import usePermission from "../../../../Hooks/usePermission";

const LazyActivitySchedule = lazify(() => import(/* webpackChunkName: "ActivitySchedule" */ '../../../../Components/Rules/ActivitySchedule'));
const LazyTargetingManager = lazify(() => import(/* webpackChunkName: "TargetingManager" */ '../../../../Components/TargetingManager'));
const LazyDatasheets = lazify(() => import(/* webpackChunkName: "TargetingManager" */ './Datasheets'));
// const LazyBidSchedule = lazy( () => import(/* webpackChunkName: "BidSchedule" */ "./BidSchedule"));

const ValidationSchema = Yup.object().shape({
  label: Yup.string()
    .trim()
    .required('Please enter a name for the rule'),
  schedule: Yup.mixed().validActivitySchedule('Check that all dates are filled out, and from/to dates are in the right order'),
  match_conditions: Yup.mixed().validTargeting('Invalid Targeting'),
  enrichment_addons: Yup.mixed().validSaleConditions('Invalid Enrichments'),
  channel_modifiers: Yup.mixed().validChannelModifiers('Invalid Channel Modifiers'),
  bidding: Yup.object().shape({
    type: Yup.string(), //
    bid_modifier: Yup.mixed().when('type', { // note, error property will be 'bidding.bid_modifier'
      is: 'modifier',
      then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : parseInt(n))).min(0, 'Bid adjustment is required').max(500, 'Maximum adjustment is 500'),
    }),
    absolute_bid: Yup.mixed().when('type', {
      is: 'absolute',
      then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : n)).min(0, 'Bid is required').max(9999, 'Maximum bid is $99.99'),
    }),
  }),
  proxy_bidding: Yup.mixed().validProxyBidding('Invalid proxy bidding configuration'),
  destination_number: PhoneNumberValidation.notRequired().nullable(),
  call_concurrency: Yup.number().transform((n) => ((isNaN(n) || n === null) ? null : parseInt(n))).max(500, 'Maximum call concurrency is 500').nullable(),
  // validate:  if destination number is set anywhere, billable duration must be set in it OR above it

  billable_duration: Yup.number()
    .test('destination-number-exists', <span>
      Billable duration is required for rules with a destination number.
      <br />
      It can be set here, or in an ancestor rule
                                       </span>, (value, testContext) => {
      // if the rule has a #, test that (1) rule has duration set, or (2) ancestor has it set
      if (testContext.parent.destination_number) {
        if (!value && !testContext.options.context.propAncestors.billable_duration) {
          return false;
        }
      }
      return true;
    })
  // .when(`$propAncestors`, (propAncestors, schema) => {
  //   console.log('index.js:WHEN propAncestors, schema', propAncestors, schema);
  //   //if itself OR ancestors has destination_number, AND no ancestor has billable duration, must require duration
  //   return propAncestors.
  // })
    .transform((n) => ((isNaN(n) || n === null) ? null : parseInt(n)))
    .min(1, 'Enter a number greater than zero or leave blank')
    .max(500, 'Maximum billable duration is 500 seconds')
    .nullable(),
  // destination_sip: Yup.object().shape({
  //   settings: Yup.object().shape({
  //     uri: Yup.string().required('URI is required'),
  //   })
  //     .nullable(),
  // })
  //   .nullable(),
  destination_sip: Yup.mixed().test('destination-sip', 'SIP configuration must have a URI.', (value) => {
    // if set, expect object with 'name' and 'settings'
    const settings = value && typeof value === 'object' ? value.settings : null;
    if (!settings) {
      return true; // empty okay
    } else if (typeof settings === 'object') {
      if (Object.keys(settings).length === 0) {
        return true;
      }
      const uri = settings.uri ? settings.uri.trim() : '';
      if (!uri) {
        return false;
      }
    }
    return true;
  }).nullable(),
  impression_urls: PixelValidation,
  click_urls: PixelValidation,
  prefill: Yup.mixed().validPrefill('Invalid prefill configuration'),
  vendor_attributes: JSONValidation,

});

// Associates a tab to a validated field (mark tab/ goto tab after error)
const FormSectionToFieldMap = {
  summary: ['label'],
  targeting: ['match_conditions'],
  bidding: ['bidding.bid_modifier', 'bidding.absolute_bid', 'proxy_bidding'],
  enrichments: ['enrichment_addons'],
  channels: ['channel_modifiers'],
  activity: ['schedule'],
  destinations: ['destination_number', 'delivery_destinations', 'prefill'],
  calls: ['billable_duration', 'call_concurrency'],
  tracking: ['impression_urls', 'click_urls'],
  advanced: ['vendor_attributes', 'internal_bid_modifier', 'qualifying_rate_start', 'bill_missed_calls', 'duplicate_window', 'buyer_name', 'buyer_group'],
};

const initializeRule = (rule) => {
  if (rule) {

    // Empty budget or caps is array, expecting object
    if (Array.isArray(rule.budgets)) rule.budgets = {};
    if (Array.isArray(rule.caps)) rule.caps = {};
    if (Array.isArray(rule.sale_attempt_caps)) rule.sale_attempt_caps = {};

    if (Array.isArray(rule.proxy_bidding)) {
      rule.proxy_bidding = {};
    }

    // Extract ids from ads collection
    if (Array.isArray(rule.ads)) {
      rule.ads = rule.ads.map((ad) => (typeof ad === 'object' ? ad.id : ad));
    }

    // Bids, zero padding: in db as 4.20, but api provides '4.2'
    if (rule.bidding.absolute_bid) {
      const strBid = rule.bidding.absolute_bid.toString();
      const dotPos = strBid.indexOf('.');
      if (dotPos > 0 && strBid.indexOf('.') === strBid.length - 2) {
        rule.bidding.absolute_bid += '0';
      }
    }

    if (!rule.prefill) rule.prefill = {};
    if (!rule.sale_confirmation) rule.sale_confirmation = {};

    rule.channel_modifiers = rule.channel_modifiers.map((cm) => {
      if (cm.bid_ceiling === null) cm.bid_ceiling = '';
      return cm;
    });

    if (Array.isArray(rule.destination_sip) || !rule.destination_sip) {
      rule.destination_sip = {};
    } else if (!rule.destination_sip.settings && Object.keys(rule.destination_sip).length > 0) {
      const config = {
        name: 'sip',
        settings: rule.destination_sip,
      };

      if (typeof config.settings.headers === 'object' && !Array.isArray(config.settings.headers)) {
        config.settings.headers = Object.entries(rule.destination_sip.headers).map(([key, value]) => {
          return { key, value };
        });
      }

      rule.destination_sip = config;
    }
  }

  return rule;
};

/*
 * Component for updating a rule
 *
 * Includes multiple tabs for editing different aspects of the rule,
 * update functions, validation, and a sliding pane for rule actions (copy, bulk update, etc).
 * Keeps state of rule object as forms are changed.
 *
 * Calls the remote API directly for create, update, and delete. For other actions it calls the
 * api provided by the context (RulesManager).
 *
 * Note: active tab is set by property (things outside of this component can update the selected tab)
 */
const RulesConfig = (props) => {
  const context = useContext(RulesContext);
  const [propAncestors, setPropAncestors] = useState({});
  const allowEdit = usePermission('orders.edit');
  initializeRule(props.rule);

  // For looking up the state of ancestor rules
  const ancestorFuncMap = {
    disabled: (r) => r.disabled,
    billable_duration: (r) => r.billable_duration,
    destination_number: (r) => r.destination_number,
    schedule_affects_siblings: (r) => r.schedule_affects_siblings,
  };

  // Rule state is the form state
  const {
    formApi, formIsDirty, formValues: rule, formErrors, formErrorSections, validationContext,
  } = useFormState(
    props.rule,
    ValidationSchema,
    null,
    FormSectionToFieldMap,
    { attributes: context.attributes, product: props.order.product },
  );

  const isNew = rule && rule.hasOwnProperty('isNew');

  // TODO: adjust this for scrolling within the modal
  const scrollToTop = (t = 0) => window.scrollTo({ top: t, behavior: 'smooth' });

  // Read up the tree to find the immediate ancestors with certain properties.
  // For some things we need to display inherited values, and validate data based on whether the values exist.
  useEffect(() => {
    window.setTimeout(() => {
      const ap = context.api.findPropAncestors(props.rule, ancestorFuncMap);
      setPropAncestors(ap);
      formApi.setValidationContext({ ...validationContext, propAncestors: ap });
    }, 500);
  }, []);

  useEffect(() => {
    // Go to errored section if not on it already
    if (formErrorSections.length && !formErrorSections.includes(props.activeTab)) {
      props.onSelectTab(formErrorSections[0]);
      scrollToTop();
    }
  }, [formErrorSections]);

  // Send dirty form state to the top because it affects rule tree
  useEffect(() => {
    context.api.setIsDirty(formIsDirty);
  }, [formIsDirty]);

  const handlePasteRule = (rule) => {
    if (typeof rule !== 'object') return;
    rule.id = props.rule.id;
    rule.parent_id = props.rule.parent_id;
    rule.priority = props.rule.priority;
    rule.rules = [];
    if (props.rule.isNew) {
      rule.isNew = props.rule.isNew;
    }

    if (props.order.product !== 'clicks') {
      rule.ads = [];
      rule.impression_urls = [];
      rule.click_urls = [];
      rule.redirect_url = '';
      rule.prefill = {};
    } else {
      rule.delivery_destinations = [];
    }

    if (props.order.product !== 'calls') {
      rule.destination_number = '';
      rule.call_concurrency = null;
      rule.billable_duration = null;
    }

    formApi.setValues({ ...rule });
  };

  //Some settings can be dayparted: bidding and caps
  const handleChangeSettingSchedule = (setting, value, timezone) => {
    formApi.setValues({
      ...rule,
      [setting]: value,
      [`${setting}_timezone`]: timezone || null,
    });
  };

  const handleCancel = () => {
    if (isNew) {
      // de-select (goes to previously selected)
      context.api.setSelected(null);
    } else {
      // reset current rule by reselecting with refresh
      context.api.setSelected(props.rule, true);
    }
    scrollToTop();
  };

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = () => {
    scrollToTop();
    // Fully de-ref and prep values for save
    const saveRule = copy(rule);
    delete saveRule.rules;
    saveRule.order_id = props.order.id;

    saveRule.label = saveRule.label.trim();

    saveRule.ad_ids = saveRule.ads;
    saveRule.datasheet_ids = saveRule.datasheets.map((ds) => ds.id);

    // format number to +18885552222  (masking should give us +1 already)
    // MRK-9038 allow any string due to Twilio syntax
    // if (saveRule.destination_number) {
    //   saveRule.destination_number = saveRule.destination_number.replace(/[^\d\+]/g, '');
    // }


    // Empty proxy bidding config, remove keys
    if (!saveRule.proxy_bidding || Object.values(saveRule.proxy_bidding).filter((i) => i).length === 0) saveRule.proxy_bidding = {};

    saveRule.schedule_type = saveRule.schedule.length > 0 ? 'inactive' : 'active';

    // Zip match condition comes in as array, must be saved as string.
    // If it's still an array (no interaction), stringify it
    if (rule.match_conditions) {
      rule.match_conditions = rule.match_conditions.map((group) => group.map((target) => {
        if ((target.attribute === 'zipcode' || target.attribute === 'sub_1') && Array.isArray(target.value)) {
          target.value = target.value.join(',');
        }
        return target;
      }));
    }

    if (rule.vendor_attributes) {
      rule.vendor_attributes = JSON.stringify(rule.vendor_attributes);
    }

    // Vendor_attributes of integration config - stringify JSON
    // MST-1264 we are moving this value to a rule-level field
    // but for now we are keeping it in the integrations for BC reasons
    if (saveRule.enrichment_addons) {
      saveRule.enrichment_addons = saveRule.enrichment_addons.map((addOn) => {
        if (addOn.integration && addOn.integration.vendor_attributes) {
          addOn.integration.vendor_attributes = JSON.stringify(addOn.integration.vendor_attributes);
        }
        return addOn;
      });
    }

    if (saveRule.proxy_bidding && !Array.isArray(saveRule.proxy_bidding)) {
      if (saveRule.proxy_bidding.integration && saveRule.proxy_bidding.integration.vendor_attributes) {
        saveRule.proxy_bidding.integration.vendor_attributes = JSON.stringify(saveRule.proxy_bidding.integration.vendor_attributes);
      }
    }

    if (saveRule.delivery_destinations) {
      saveRule.delivery_destinations = saveRule.delivery_destinations.map((dest) => {
        if (dest.integration && dest.integration.vendor_attributes) {
          dest.integration.vendor_attributes = JSON.stringify(dest.integration.vendor_attributes);
        }
        return dest;
      });
    }

    if (saveRule.channel_modifiers) {
      saveRule.channel_modifiers = Object.values(saveRule.channel_modifiers);
    }

    if (saveRule.destination_sip?.settings && Object.keys(saveRule.destination_sip.settings).length) {
      // Discard name, label... just the settings
      saveRule.destination_sip = saveRule.destination_sip.settings;
      // Transform array of objects with literal key/value attributes to an object of { key1:value1, key2:value2, etc }
      if (saveRule.destination_sip.headers) {
        const headers = {};
        saveRule.destination_sip.headers.map((header) => {
          headers[header.key] = header.value;
        });
        saveRule.destination_sip.headers = headers;
      }
    } else {
      saveRule.destination_sip = null;
    }

     context.api.saveRule(saveRule, isNew);
  };

  return (

    <PageSection
      className="rules-config-section"
    >

      <LoadingBar name="rulesConfig" active={props.isUpdating} scrollToTop />

      <ActionsPanel
        rule={rule}
        isOpen={props.actionsOpen}
        isRootRule={props.isRoot}
        isDirty={props.isDirty}
        onChange={formApi.handleOnChange}
        onPasteRule={handlePasteRule}
        onSaveRule={handleSave}
      />

      { !rule && (
        <div className="rule-none-selected">Please select a rule to configure</div>
      )}

      { rule && (
        <>
          <Header
            rule={rule}
            onChange={formApi.handleOnChange}
            isUpdating={props.isUpdating}
            errors={formErrors}
          />

          {rule.disabled && (
          <div className="disabled-notice mt-2">
            <StandardAlert color="danger" icon="alert" className="validation mb-0">
              This rule is disabled. Bid, attributes, and descendant rules will not be applied.
              {allowEdit && <Button color="link" className="p-0 ms-2" onClick={() => context.api.disableRule(rule, false)}>Enable Rule</Button>}
            </StandardAlert>
          </div>
          ) }

          {/* Rule is not disabled but ancestor is */}
          {propAncestors.disabled && (
          <div className="disabled-notice mt-2">
            <StandardAlert color="danger" icon="alert" className="validation mb-0">
              The ancestor rule &quot;
              {propAncestors.disabled.label}
              &quot; has been disabled. This rule will not be applied.
            </StandardAlert>
          </div>
          ) }

          {allowEdit && (
            <NavTabs
              activeTab={props.activeTab}
              handleSelectTab={props.onSelectTab}
              isSectionValid={formApi.isSectionValid}
              errorTabs={formErrorSections}
              product={props.order.product}
              allowAdvanced={props.allowAdvanced}
            />
          )}


          <div className="rule-config">
            <TabContent activeTab={props.activeTab} className="rules-transition">

              <TabPane tabId="summary">
                <Summary
                  rule={rule}
                  timezone={props.order.account.timezone}
                  gotoTab={props.onSelectTab}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                  channels={props.channels}
                  accountAds={props.accountAds}
                  product={props.order.product}
                  isRootRule={props.isRoot}
                  isDirty={formIsDirty}
                />
              </TabPane>

              {allowEdit && (
                <>
              <TabPane tabId="targeting">

                <div className="mb-3">
                  <div className="d-flex">

                    <div className="me-1">
                      <Input
                        name="match_is_required"
                        type="checkbox"
                        checked={rule.match_is_required}
                        disabled={!allowEdit}
                        onChange={(e) => formApi.handleOnChange('match_is_required', e.target.checked)}
                      />
                    </div>
                    <div className="flex-fill">
                      <Label>
                        Match is required for bid
                      </Label>
                      <FormText color="muted ms-2">
                        If this option is selected, and the rule does not match, the bidding process for this order will stop.
                      </FormText>
                      {/*  Todo an example here would be nice */}
                    </div>
                  </div>

                  <div className="mt-1 d-flex">
                    <div className="me-1">
                      <Input
                        name="is_standalone"
                        type="checkbox"
                        checked={rule.is_standalone}
                        disabled={!allowEdit}
                        onChange={(e) => formApi.handleOnChange('is_standalone', e.target.checked)}
                      />
                    </div>
                    <div className="flex-fill">
                      <Label>
                        Standalone Rule
                      </Label>
                      <FormText color="muted ms-2">
                        If this option is selected, the rule does not pass any of its settings along to its sibling rules.
                      </FormText>
                      {/*  Todo an example here would be nice */}
                    </div>
                  </div>
                </div>


                <LazyTargetingManager
                  conditions={rule.match_conditions}
                  onUpdate={(groups) => formApi.handleOnChange('match_conditions', groups)}
                  attributes={context.attributes}
                  attributeGroups={context.attributeGroups}
                  errors={formErrors.match_conditions}
                  className="advertiser"
                  allowEdit={allowEdit}
                />

              </TabPane>

              <TabPane tabId="datasheets">

                <LazyDatasheets
                  datasheets={rule.datasheets}
                  accountDatasheets={props.datasheets}
                  accountId={props.order.account.id}
                  onChange={(sheets, dirtyForm) => formApi.handleOnChange('datasheets', sheets, dirtyForm)}
                  errors={formErrors.datasheets}
                />

              </TabPane>

              <TabPane tabId="bidding">
                <Bidding
                  isActiveTab={props.activeTab === 'bidding'}
                  accountId={props.order.account.id}
                  {...rule.bidding}
                  bid_ceiling={rule.bid_ceiling}
                  proxyBidding={rule.proxy_bidding}
                  product={props.order.product}
                  exclusivity={rule.exclusivity}
                  schedule={rule.schedule_modifiers}
                  scheduleTimezone={rule.schedule_modifiers_timezone}
                  onChangeSchedule={handleChangeSettingSchedule}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                />

              </TabPane>

              <TabPane tabId="enrichments">

                <Enrichments
                  addOns={rule.enrichment_addons}
                  onChange={(sc) => formApi.handleOnChange('enrichment_addons', sc)}
                  errors={formErrors.enrichment_addons || {}}
                  loadIntegrations={() => {
                    return context.api.getIntegrationDefinitionsByType('enrichment')
                  }}
                />

              </TabPane>

              <TabPane tabId="sale_confirmation">

                <SaleConfirmation
                  saleConfirmation={rule.sale_confirmation}
                  onChange={(sc) => formApi.handleOnChange('sale_confirmation', sc)}
                  errors={formErrors.sale_confirmation || {}}
                />

              </TabPane>

              <TabPane tabId="activity">

                <LazyActivitySchedule
                  schedule={rule.schedule}
                  scheduleType={rule.schedule_type}
                  scheduleAffectsSiblings={rule.schedule_affects_siblings}
                  onChangeSchedule={(s) => formApi.handleOnChange('schedule', s)}
                  onChangeScheduleType={(type) => formApi.handleOnChange('schedule_type', type)}
                  onChangeScheduleAffectsSiblings={(val) => formApi.handleOnChange('schedule_affects_siblings', val)}
                  timezone={props.order.account.timezone}
                  propAncestors={propAncestors}
                  isRootRule={props.isRoot}
                  error={formErrors.schedule}
                />

              </TabPane>

              <TabPane tabId="budget">
                <Budgets
                  product={props.order.product}
                  budgets={rule.budgets}
                  caps={rule.caps}
                  saleAttemptCaps={rule.sale_attempt_caps}
                  onChange={formApi.handleOnChange}
                  onChangeSchedule={handleChangeSettingSchedule}
                  schedule={rule.schedule_caps}
                  scheduleTimezone={rule.schedule_caps_timezone}
                  scheduleSaleAttemptCaps={rule.schedule_sale_attempt_caps}
                  scheduleSaleAttemptCapsTimezone={rule.schedule_sale_attempt_caps_timezone}
                />
              </TabPane>

              {props.order.product === 'clicks' && (
              <TabPane tabId="ads">
                <Ads
                  ruleAds={rule.ads}
                  accountAds={props.accountAds}
                  overrideParent={rule.override_parent_ads}
                  onChangeAds={formApi.handleOnChange}
                  isRootRule={props.isRoot}
                  accountId={props.order.account.id}
                />
              </TabPane>
              )}
              <TabPane tabId="channels">
                <Channels
                  rule={rule}
                  isRootRule={props.isRoot}
                  channels={props.channels}
                  modifiers={rule.channel_modifiers}
                  overrideParentChannelModifiers={rule.override_parent_channel_modifiers}
                  onChange={formApi.handleOnChange}
                  errors={formErrors.channel_modifiers || {}}
                />

              </TabPane>

              <TabPane tabId="destinations">
                <Destination
                  redirectUrl={rule.redirect_url}
                  redirectUrlTemplate={rule.redirect_url_template}
                  destinationNumber={rule.destination_number}
                  deliveryDestinations={rule.delivery_destinations}
                  destinationSip={rule.destination_sip}
                  callStartedWebhooks={rule.call_started_webhooks}
                  callEndedWebhooks={rule.call_ended_webhooks}
                  tokens={props.tokens}
                  prefill={rule.prefill}
                  onChange={formApi.handleOnChange}
                  product={props.order.product}
                  verticalId={props.order.vertical.id}
                  propAncestors={propAncestors}
                  errors={formErrors}
                />

              </TabPane>

              <TabPane tabId="tags">
                <Tag
                  tags={rule.tags}
                  onChange={(tags) => formApi.handleOnChange('tags', tags)}
                />

              </TabPane>

              {props.order.product === 'calls' && (
              <TabPane tabId="calls">
                <CallSettings
                  concurrency={rule.call_concurrency}
                  billableDuration={rule.billable_duration}
                  propAncestors={propAncestors}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                />
              </TabPane>
              )}

              {/* For when we do scheduling:
                 concurrencySchedule={rule.call_concurrency_schedule}
                 */}

              {props.order.product === 'clicks' && (
              <TabPane tabId="tracking">
                <Tracking
                  impressionUrls={rule.impression_urls}
                  clickUrls={rule.click_urls}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                />
              </TabPane>
              )}

              <TabPane tabId="advanced">
                {props.allowAdvanced && (
                <Advanced
                  internalBidModifier={rule.internal_bid_modifier}
                  trackQualifyingRate={rule.track_qualifying_rate}
                  ignoreSaleRate={rule.ignore_sale_rate}
                  qualifyingRateStart={rule.qualifying_rate_start}
                  excludeProfitReporting={rule.exclude_profit_reporting}
                  billMissedCalls={rule.bill_missed_calls}
                  duplicateWindow={rule.duplicate_window}
                  vendorAttributes={rule.vendor_attributes}
                  tokens={props.tokens}
                  timezone={props.order.account.timezone}
                  buyer_name={rule.buyer_name}
                  buyer_group={rule.buyer_group}
                  maxUnqualifiedCalls={rule.max_unqualified_calls}
                  unqualifiedCallsStart={rule.unqualified_calls_start}
                  onChange={formApi.handleOnChange}
                  errors={formErrors}
                />
                )}
              </TabPane>
              </>
              )} {/* allow edit */}
            </TabContent>
          </div>

          { rule && allowEdit && (
          <div className="mt-5 d-flex justify-content-end align-items-center">

              { (formIsDirty || Object.entries(formErrors).length > 0) && (
              <span className="me-3 d-inline-block dirty-alert">
                <FaIcon icon="exclamation-circle" color="#990000" size="sm" />
&nbsp;
                {Object.entries(formErrors).length > 0
                  ? <span>Please correct errors before saving.</span>
                  : <span>This rule has been modified. Save your changes or cancel.</span>}
              </span>
              )}

              <Button
                size="sm"
                onClick={handleCancel}
                color="link"
                disabled={(!rule.isNew && !formIsDirty) || props.isUpdating}
                className="me-2"
              >
                Cancel
              </Button>

              <Button
                size="sm"
                onClick={handleSave}
                color="primary"
                disabled={props.isUpdating}
              >
                Save Rule
              </Button>

          </div>
          )}
        </>
      )}

    </PageSection>

  );
};

RulesConfig.propTypes = {
  rule: PropTypes.object,
  attributes: PropTypes.object,
  attributeLookup: PropTypes.object,
  product: PropTypes.string,
  channels: PropTypes.array,
  accountAds: PropTypes.array,
  datasheets: PropTypes.array,
  tokens: PropTypes.array,
  timezone: PropTypes.string,
  isDirty: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isRoot: PropTypes.bool,
  activeTab: PropTypes.string,
  onSelectTab: PropTypes.func,
  actionsOpen: PropTypes.bool,
  allowAdvanced: PropTypes.bool,
};

export default RulesConfig;
