import React, { useContext, useState } from 'react';
import {
  Row, Col, Button, Nav, TabContent, TabPane, ModalFooter, ModalBody,
} from 'reactstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useFormState from '../../../Hooks/useFormState';
import {notify, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import { FormTabFlat } from '../../../Components/Form/FormCommon';
import LoadingBar from '../../../Layout/LoadingBar';
import ClickTags from './ClickTags';
import Details from './Details';
import SeedDataBuilder from '../../../Components/Form/SeedDataBuilder';
import { AppContext } from '../../../Providers/AppProvider';

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter a source name'),
  product: Yup.string().trim().required('Please select a product'),
  // token: Yup.string().trim().when(['$isNew'], (isNew, schema) => {
  //   if (isNew) {
  //     // allow it to be anything or empty (will auto-generate)
  //     return schema.nullable();
  //   }
  //   return schema.required('Token is required');
  // }),
  vertical_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select a vertical'),
  //vendor_id: Yup.number().transform((n) => (isNaN(n) ? 0 : n)).moreThan(0, 'Please select a vendor'),
  vendor_billable_duration: Yup.number().transform((n) => ((isNaN(n) || n === null) ? null : parseInt(n))).max(500, 'Maximum billable duration is 500 seconds').nullable(),
});

/*
 * Component for editing or creating a source
 */
const Source = ({ source, closeModal }) => {
  const isNew = source.id === 0;
  const app = useContext(AppContext);

  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      ...source,
      vertical_id: source.vertical.id,
      //vendor_id: source.vendor.id,
    },
    ValidationSchema,
    null,
    null,
    { product: source.product, isNew }, // validationContext allows conditional validation
  );

  const [activeTab, setActiveTab] = useState('details');

  // SeedDataBuilder provides object
  const handleUpdateSeedData = (obj) => formApi.handleOnChange('seed_data', obj);

  const handleSelectTab = (activeTab) => setActiveTab(activeTab);

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating source...' : 'Saving source...');
    let result;
    const data = { ...formValues };

    data.name = data.name.trim();
    //if (!data.vendor_id && data.vendor) data.vendor_id = data.vendor.id;
    if (!data.vertical_id && data.vertical) data.vertical_id = data.vertical.id;

    // format number to +18885552222  (masking should give us +1 already)
    if (data.phone_number) {
      data.phone_number = data.phone_number.replace(/[^\d\+]/g, '');
    }

    data.seed_data = data.seed_data && Object.keys(data.seed_data).length > 0
      ? JSON.stringify(data.seed_data)
      : null;

    // We're not updating these from the sources end yet
    delete data.channel_eligibilities;

    if (isNew) {
      result = await app.api.endpoints.sources.create(data);
    } else {
      result = await app.api.endpoints.sources.update(data);
    }

    app.showLoader(false);

    // {form:
    //   token.token: "This token already exists on another source"
    //   message: ""
    //   name: "Invalid Request"
    //   status: 400
    // }

    if (result) {
      closeModal(true);
    } else {
      // Could get 'name already exists' error
      if (app.api.error.form && app.api.error.form['token.token']) {
        // TODO: error handling for tabs
        notify(`Unable to save source: ${app.api.error.form['token.token']}`, 'error');
      } else {
        notify(`Unable to save source: ${app.api.error.name}`, 'error');
      }
    }
  };

  return (
    <>
      <ModalBody>
        <LoadingBar name="modal" />

        {!formIsValid
        && (
          <StandardAlert color="warning">
            Please correct the errors below before continuing
          </StandardAlert>
        )}

        <Nav className="mastodon-tabs">
          <FormTabFlat
            id="details"
            label="Details"
            activeTab={activeTab}
            setActive={handleSelectTab}
          />

          {source.product === 'clicks' && (
            <FormTabFlat
              id="tags"
              label="Click Tags"
              activeTab={activeTab}
              setActive={handleSelectTab}
            />
          )}

          <FormTabFlat
            id="seed_data"
            label="Seed Data"
            activeTab={activeTab}
            setActive={handleSelectTab}
          />

        </Nav>

        <div className="source-form">

          <TabContent
            activeTab={activeTab}
            className="rules-transition"
          >
            <TabPane tabId="details">
              <Details
                source={formValues}
                errors={formErrors}
                onChange={formApi.handleOnChange}
                isNew={isNew}
              />
            </TabPane>

            {source.product === 'clicks' && (
              <TabPane tabId="tags">
                <Row>
                  <Col sm={12} md={9}>
                    <ClickTags clickTags={formValues.click_tags} onChange={formApi.handleOnChange} />
                  </Col>
                </Row>
              </TabPane>
            )}

            <TabPane tabId="seed_data">
              <Row>
                <Col sm={12} md={9}>
                  <SeedDataBuilder
                    seedData={formValues.seed_data}
                    verticalId={isNew ? 0 : source.vertical.id}
                    onUpdate={handleUpdateSeedData}
                  />
                </Col>
              </Row>

            </TabPane>

          </TabContent>

        </div>
      </ModalBody>


      <ModalFooter>
        <Button
          onClick={closeModal}
          color="link"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          size="sm"
        >
          Save
        </Button>

      </ModalFooter>

    </>
  );
};

Source.propTypes = {
  source: PropTypes.object,
  closeModal: PropTypes.func,
};

export default Source;
